import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { articles } from './data.js';
import unsplash from '../../assets/unsplash.jpg';

const Article = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    // Fetch article from the API
    // This is just a placeholder example
    setArticle(articles.find((article) => article.id === parseInt(id)));
  }, [id]);

  if (!article)
    return (
      <div className="container mx-auto flex items-center justify-center p-4 text-white md:min-h-screen">
        <h1 className="text-3xl">Loading...</h1>
      </div>
    );
  return (
    <div className="container mx-auto p-4 pt-28 text-white md:min-h-screen">
      <div className="relative mx-4 max-w-3xl md:mx-auto">
        <img
          src={unsplash} // article.imageSrc need to fix this later, temporary shitty fixs
          alt="unsplash"
          className="h-full max-h-64 w-full rounded-lg object-cover"
        />
        {/* <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-transparent"></div> */}
      </div>
      <div className="mx-4 max-w-3xl pt-10 text-justify md:mx-auto">
        <h1 className="mb-4 text-left text-5xl font-bold">{article.title}</h1>
        <h2 className="mb-12 text-xl text-primary">{article.author}</h2>
        {/* <p className="text-lg">{article.content}</p> */}
        {article.content.split('\n').map((line, index) => (
          <p key={index} className="text-lg">
            {line}
            <br />
          </p>
        ))}
      </div>
    </div>
  );
};

export default Article;
