import React from 'react';
import { FaWrench } from 'react-icons/fa';
import { FaPencilAlt } from 'react-icons/fa';
import { FaChartLine } from 'react-icons/fa';
import RevealOnScroll from '../RevealOnScroll';

const InfoSection = () => {
  return (
    <>
      <div className="px-6 py-28 md:min-h-screen md:px-24">
        <RevealOnScroll>
          <p className="text-l font-bold text-primary">DEPARTMENTS</p>
          <h1 className="text-3xl font-bold text-white md:text-5xl">
            Build. Educate. Invest.
          </h1>

          <p className="text-l py-20 text-gray-300  md:px-80">
            While St. Andrews offers traditional finance-typically equity based-
            exposure to its students, interests in emerging asset classes have
            been left unaddressed. Given the opaque, typically negative,
            narrative surrounding web 3 and blockchain based assets, the
            university has yet to establish a club to satiate the interests
            shared by many students in crypto.
          </p>
        </RevealOnScroll>
        <RevealOnScroll>
          <div className="flex flex-col justify-around text-left text-sm md:flex-row md:divide-x md:divide-gray-500">
            <div className="flex w-full flex-col space-y-8 pl-8 md:w-1/3 md:pt-6">
              <FaWrench className="text-4xl text-primary" />
              <h2 className="text-xl font-bold text-white">Engineering</h2>
              <p className="pr-6 text-gray-300">
                Web3 offers exciting opportunities for computer scientists and
                programmers. This section of the club builds the technical
                skills required in blockchain development. Beyond learning
                programming languages, members will develop technically acute
                solutions to complicated Web3 issues and gain invaluable
                practical experience.
              </p>
            </div>
            <div className="flex w-full flex-col space-y-8 pl-8 pt-6 md:w-1/3">
              <FaPencilAlt className="text-4xl text-primary" />
              <h2 className="text-xl font-bold text-white">Writing</h2>
              <p className="pr-6 text-gray-300">
                The writing team reports on Web3 news and presents it in
                digestible terms to both newcomers and those with experience in
                blockchain. Writers can express their thoughts and opinions on
                upcoming developments or existing projects in easily
                interpretable and engaging articles. This section is an ideal
                way for aspiring writers to gain journalistic experience.
              </p>
            </div>
            <div className="flex w-full flex-col space-y-8 pl-8 pt-6 md:w-1/3">
              <FaChartLine className="text-4xl text-primary" />
              <h2 className="text-xl font-bold text-white">Investing</h2>
              <p className="pr-6 text-gray-300">
                Through a traditional finance curriculum, members of this
                section learn the basics of fundamental, technical, and market
                analysis. Analysts explore decentralised finance, metaverses,
                digital art, and exchanges. Their research culminates in an
                independently chosen investment proposal, mirroring a real-world
                financial pitch.
              </p>
            </div>
          </div>
        </RevealOnScroll>
      </div>
    </>
  );
};

export default InfoSection;
