// import newspaperImg from '../../assets/newspapers.webp';
import bg from '../../assets/bg.jpg';
import unsplash from '../../assets/unsplash.jpg';

const article1 = `To what extent would you go to maintain a right to privacy? For Coinbase, the “largest cryptocurrency exchange in the United States”,[1] the answer has proven to be surprising. \n
In March of 2023, the United States Supreme Court heard oral arguments in its first ever cryptocurrency-related case, Coinbase, Inc. v. Bielski. Shockingly, the lynchpin of the case didn’t involve trailblazing digital marketplace law or even interpreting cryptocurrency regulations, as one might expect. Rather, the question for the court was broadly one of arbitration. \n
Arbitration is a methodological alternative to litigation, its primary benefits being that it is confidential (i.e., private, unlike the public court system), it precludes consequent litigation, and it is relatively inexpensive. Perhaps also at play is the fact that “arbitrators are not subject to the [same] Federal Rules of Evidence” as courts, hinting at the privately low-key , unobtrusive nature of arbitration.[2] \n
As for Coinbase, it ironically raised its case in the Supreme Court solely in hopes of avoiding more litigation — and bolstering its odds to arbitrate. But why? \n
Back in the fall of 2022, Coinbase had racked up a handful of lawsuits in districts across the U.S. from Georgia to California raised by investors and customers alike, including an investigation by the SEC. Many filed complaints alleged security concerns, in which hundreds of users claimed to have been denied access to their Coinbase accounts for up to months at a time. The complaint filed in one of these cases, Kattula v. Coinbase Glob., emphasized the inequity of lock-outs, citing “the extreme volatility of cryptocurrencies’ value – with freefalls of 40% within 24 hours not unheard of… lead[ing] to severe financial loss to account holders.”[3] If account holders couldn’t access their accounts, they couldn’t sell crypto assets at their own will or discretion. Users thus sought upwards of $5 million worth of damages in this case alone. 	Other class action cases raised concerns about fraudulent activity, misleading sales practice, and a New Jersey lawsuit even accused Coinbase of “violating federal law by listing securities on its trading platform.”[4] Under tremendous litigious pressure, Coinbase hoped to sidestep a series of scandalous lawsuits that would plunge the cryptocurrency industry into even further disrepute. Instead, it began seeking from courts an alternative — arbitration. \n
The core of the upcoming thicket of legalities is: 1) timing, and 2) maintaining confidentiality. In the specific background of the SCOTUS case at hand, Coinbase v. Bielski, it is important to know that Coinbase had been previously denied motion for arbitration by two federal district courts — leaving the class-action lawsuits potentially active. In accordance with their rights under the Federal Arbitration Act, Coinbase immediately appealed the lower courts’ ruling to the 9th Circuit Court of Appeals, hoping for a different outcome. Namely, Coinbase again sought a motion to pursue the alternatively low-profile form of dispute resolution – arbitration. \n
What follows in the Supreme Court’s case, Coinbase, Inc. v. Bielski, is a discussion of what occurs in the interim — whether district courts are allowed to proceed immediately with litigation considering their anti-arbitration rulings, while Coinbase awaits the final say on arbitrability from the 9th Circuit. If it rules in favor of arbitrability, the lower district court proceedings become void, raising the legal question of: why hold a potentially discardable trial rather than just wait? Timing here is key — a half-baked district trial could have multiple negative ramifications, as Coinbase is keenly aware. Its confidentiality, and thus, reputation,  hinges on SCOTUS’ interpretation of Section 16(a) of the Federal Arbitration Act, (which dictates policies on stays in such scenarios.) \n
Given the common-sense application, it appears likely that Congress broadly intended a stay (i.e., halt) on district court proceedings while an appeal lingers, possibly for years, in circuit courts. This is the view Coinbase argues. Otherwise, if district courts don’t stay the trial, those benefits that petitioners seek in appealing for arbitration would be lost — in such a view, courts could potentially carry out a full proceeding. This hypothetical trial would entirely defeat the point of seeking the privacy of arbitration. \n
In the hour-long oral argument, Neal Katyal, counsel for petitioner Coinbase, frequently cited a metaphor that sums it up. Katyal states that such a trial would require backtracking equivalent to ‘putting toothpaste back into its bottle’: in so many words, its effects are irreversible. The primary concern being that “confidentiality concerns…will get undone in the interim.”[5] Legally and practically, Coinbase’s argument to interpret 16(a) to compel district courts to stay proceedings awaiting interlocutory appeal was convincing. \n
For this, among other precedent-related reasoning, the Supreme Court ruled 5-4 in favor of petitioners — Coinbase — on June 23, 2023. Cryptocurrency had now officially made its debut in the sphere of elite American politics and, according to judicial headlines, it emerged victorious, winning a ruling that keeps them out of lower courts — for now. Could this SCOTUS ruling signal a shifting tide in American sentiment regarding blockchain, NFTs, and all other facets of the crypto revolution? \n
Not  necessarily. In fact, the converse might just be true. To anyone who studies this case carefully, it seems less of a win than a gamble. What Coinbase has succeeded in doing in SCOTUS is nothing more than establishing trivial precedent that trial  proceedings must be stayed in a very slim context relating to a subsection of interlocutory appeals. Hence, it is, on its surface, a seemingly nonessential case turning on federal civil procedure’s legal minutiae. \n
But at its heart, this ruling favors arbitrability and, more importantly, its associate — confidentiality. It's a ruling that favorably induces Coinbase to as little class action litigation as possible — for now. Still pending, the 9th Circuit might yet rule against arbitrability, in which case Coinbase would face the very trials it’s been so desperately avoiding. \n
As Justice Kavanaugh hinted in his majority opinion, Coinbase has gambled — at the cost of significant legal expenditure in the highest courts of America — to arbitrate.[6] Why go to such extremes to maintain what is, at the end of the day, a right to privacy? After all, the legal question was motivated by Coinbase’s goal of keeping things out of the courts, out of the hands of the public, and out of view. In other words, Coinbase, Inc. v. Bielski recounts an all-too-familiar story of the lengths a company will go to keep its allegations out of the public eye. To conspirators, it raises suspicions as to what exactly might be hiding behind the company’s high stakes closed doors. And to crypto sceptics, it epitomizes the elusivity expected of an industry founded under a pseudonym, championing decentralization, and dealing in anonymous transactions. \n
So perhaps the better question for the court is: should the confidentiality afforded in arbitration even be an option for publicly accountable businesses like Coinbase? Or should a company willing to go to such lengths to keep mysterious records out of the public eye really be isolated from the proper scrutiny of America’s legal system? And what ripple effects will SCOTUS’ ruling have for customers? If users are allegedly being misled or wronged, is it right to resolve these multi-million-dollar disputes in a less rigorous evidentiary setting? \n
Many, including Bielski, would say an emphatic no. This case leaves many questions unresolved, and one can expect to see more legal challenges to the cryptocurrency exchange taking up court dockets in the future. \n
Disclosure: The perspectives presented in this article belong solely to the author and do not necessarily reflect the viewpoints held by The St Andrews Blockchain Society. Nor should these views be considered as professional financial advice. \n
`;

const article2 = `As an institution, the University of St Andrews does not offer any formal undergraduate education concerning Web 3 or blockchain and offers limited exposure to financial technology and emerging asset classes. Many students are already interested in digital assets but have not had any opportunities to explore the industry. Further, while students will have heard of cryptocurrencies and NFTs, most do not understand what they are and how they can be used. Similarly, students often have negative connotations associated with crypto movements of the past and do not have realistic or accurate understandings of the underlying innovations. Blockchain offers opportunities that go far beyond just trading cryptocurrencies, most of which are completely unknown to the average student. Therefore, this article will introduce the St Andrews Blockchain Society (STABS), a new club intended to fulfil this unaddressed interest among students, bridging the gap between extracurriculars and academics for students of all backgrounds and experience levels. \n 
Blockchain is an incredibly large and complex field and is often challenging for beginners. It is, however, multifaceted and interdisciplinary, offering opportunities to students with a variety of skill sets. We have structured STABS to reflect this, dividing the society into investing, development, and writing subsections. \n
Through investing, students will learn a variety of trading fundamentals and techniques, such as technical, fundamental, and sentimental analysis. While these methods will primarily be applied to cryptocurrencies and non-fungible tokens, they will also be relevant to traditional investing practices. Members of this section will engage in a competition where they research a chosen protocol before presenting it to the committee. The society’s funds will be allocated to the winner's chosen project, mirroring a real-world investment proposal. \n
The development section will house tremendous opportunities for computer science and programming students, enabling them to develop their chosen coding skills. This team will focus on semester-long projects, beginning with the construction of the society's website. This website will act as the foundation for all society operations. In the second half of the year, developers will have the freedom to select their next project as a subcommittee. These projects will teach practical skills to new and experienced coders alike. \n
The writing section is a great place for those new to blockchain to learn, explore, and publish their own work. The primary goal of the section is to write concise articles to educate fellow students and the broader public on everything from blockchain fundamentals to regulatory filings, metaverse developments to derivatives innovations, or traditional finance applications to programming changes. Articles can be as simple as defining and explaining key concepts, or as complex as unraveling the intricacies of a particular protocol. \n
Throughout the year, there will also be a variety of club-wide activities such as pub socials, networking events, resume workshops, and speaker events. Club members will be able to work in teams with students of similar backgrounds and skill sets, as well as make connections beyond just their specific subsection. They will learn the necessary fundamentals and have the freedom to follow their own lines of inquiry to further develop their passion and find where blockchain intersects with their studies and interests. This club will be an effective and enjoyable way for students to learn about blockchain technologies, aiming to shift the narrative surrounding Web3 on campus and empower students to be informed and at the forefront of these cutting-edge developments. `;

export const articles = [
  {
    id: 1,
    title: 'Coinbase in Court: A Legal Battle for Privacy',
    summary:
      'To what extent would you go to maintain a right to privacy? For Coinbase, the “largest cryptocurrency exchange in the United States”, the answer has proven to be surprising. ',
    imageSrc: unsplash,
    author: 'Piper Madden',
    content: article1,
  },
  {
    id: 2,
    title: 'The St. Andrews Blockchain Society',
    summary:
      'As an institution, the University of St. Andrews does not offer any formal undergraduate education concerning Web 3 or blockchain and offers limited exposure to financial technology and emerging asset classes. Many students are already interested in digital assets but have not had any opportunities to explore the industry. Further, while students will have heard of cryptocurrencies and NFTs, most do not understand what they are and how they can be used. Similarly, students often',
    imageSrc: bg,
    author: 'STABS',
    content: article2,
  },
  // {
  //   id: 3,
  //   title: 'Article 3',
  //   summary: 'Summary of Article 1',
  //   imageSrc: newspaperImg,
  //   author: 'Author 3',
  // },
  // {
  //   id: 4,
  //   title: 'Article 4',
  //   summary: 'Summary of Article 1',
  //   imageSrc: newspaperImg,
  //   author: 'Author 4',
  // },
];
