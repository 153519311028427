// P5Wrapper.js
import { useEffect, useRef } from 'react';
import p5 from 'p5';
import sketch from './sketch';

function P5Wrapper() {
  const wrapperRef = useRef();

  useEffect(() => {
    new p5(sketch, wrapperRef.current);

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.innerHTML = ''; // Clean up the p5.js sketch on component unmount
      }
    };
  }, []);

  return (
    <div ref={wrapperRef} className="absolute left-0 top-0 h-full w-full" />
  );
}

export default P5Wrapper;
