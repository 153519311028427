const EventItem = ({ event }) => {
  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    if (minute === 0) return `${day} ${month} ${year}, ${hour}:00`;
    return `${day} ${month} ${year}, ${hour}:${minute}`;
  }

  return (
    <div className="flex h-auto w-auto flex-col items-start gap-2 rounded-lg border border-white p-8">
      <div className="text-xl font-bold">{event.name}</div>
      <div className="">
        Date: {event.date ? formatDate(event?.date) : 'To be confirmed'}
      </div>
      <div className="">Location: {event.location}</div>
      <div className="">Description: {event.description}</div>
    </div>
  );
};

export default EventItem;
