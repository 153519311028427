import React from 'react';
import {
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineLinkedin,
  AiOutlineMail,
} from 'react-icons/ai';

function Socials() {
  return (
    <div className="flex items-center gap-4">
      <a
        href="https://www.instagram.com/ustablockchain/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="
        rel="noreferrer"
        target="_blank"
      >
        <AiOutlineInstagram className="text-3xl text-white hover:text-primary" />
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=61550666083879&locale=en_GB"
        rel="noreferrer"
        target="_blank"
      >
        <AiOutlineFacebook className="text-3xl text-white hover:text-primary" />
      </a>
      <a
        href="https://www.linkedin.com/company/uni-st-andrews-blockchain-society/"
        rel="noreferrer"
        target="_blank"
      >
        <AiOutlineLinkedin className="text-3xl text-white hover:text-primary" />
      </a>
      <a href="mailto:aptus@st-andrews.ac.uk" rel="noreferrer" target="_blank">
        <AiOutlineMail className="text-3xl text-white hover:text-primary" />
      </a>
    </div>
  );
}

export default Socials;
