import React from 'react';
import Socials from '../Socials';

const Footer = () => {
  return (
    <div className="pt-5">
      <hr />
      <div className="flex items-center justify-between p-8">
        <p className="font-light text-white">
          © 2023 St. Andrew's Blockchain Society. All rights reserved.
        </p>
        <Socials />
      </div>
    </div>
  );
};

export default Footer;
