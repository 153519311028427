import { BsArrowDownCircle } from 'react-icons/bs';

// ScrollDownButton.tsx
const ScrollDownButton = () => {
  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight, // Adjust as needed for your layout
      behavior: 'smooth',
    });
  };

  return (
    <div className="absolute inset-x-0 bottom-20 mt-10">
      <button
        className="animate-bounce cursor-pointer"
        onClick={scrollToContent}
        aria-label="Scroll down"
      >
        <BsArrowDownCircle className="h-12 w-12 text-white" />
      </button>
    </div>
  );
};

export default ScrollDownButton;
