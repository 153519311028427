import { React, useState } from 'react';
import SideBar from '../components/SideBar/SideBar';
import NavBar from '../components/NavBar/NavBar';
import bruno from '../assets/headshots2324/096A9047.jpg';
import osian24 from '../assets/headshots2324/OSIAN.png';
import jake from '../assets/headshots2324/096A9055.jpg';
import kabir from '../assets/headshots2324/096A9082.jpg';
import jimmy from '../assets/headshots2324/096A9092.jpg';
import cole from '../assets/headshots2324/096A9133.png';
import finn from '../assets/headshots2324/096A9107.jpg';
import borya from '../assets/headshots2425/IMG_2608.JPG';
import ruby from '../assets/headshots2425/IMG_2609.JPG';
import cayetana from '../assets/headshots2425/IMG_2610.JPG';
import hugo from '../assets/headshots2425/IMG_2611.JPG';
import lauren from '../assets/headshots2425/IMG_2612.JPG';
import lottie from '../assets/headshots2425/IMG_2613.JPG';
import johan from '../assets/headshots2425/IMG_2614.JPG';
import osian25 from '../assets/headshots2425/IMG_2615.JPG';
import parker from '../assets/headshots2425/IMG_2616.JPG';
import piper from '../assets/headshots2425/IMG_2617crop.JPG';
import zara from '../assets/headshots2425/IMG_2618.JPG';
import ProfileImage from '../components/ProfileImage/ProfileImage';

// committees for each year
const committees = [
  {
    year: '24/25',
    members: [
      {
        name: 'Osian Guthrie',
        position: 'President',
        image: osian25,
      },
      {
        name: 'Zara Ward',
        position: 'Vice President',
        image: zara,
      },
      {
        name: 'Lottie Plaskwa',
        position: 'Head Editor',
        image: lottie,
      },
      {
        name: 'Piper Madden',
        position: 'Head Editor',
        image: piper,
      },
      {
        name: 'Hugo Okada',
        position: 'Head of Fund',
        image: hugo,
      },
      {
        name: 'Ruby Sloan',
        position: 'Creative Director',
        image: ruby,
      },
      {
        name: 'Parker Caleshu',
        position: 'Treasurer',
        image: parker,
      },
      {
        name: 'Lauren Rae',
        position: 'Secretary',
        image: lauren,
      },
      {
        name: 'Johan Mare',
        position: 'Head of Engineering',
        image: johan,
      },
      {
        name: 'Borya D. Taspinar-Sherifovsky',
        position: 'Social Sec',
        image: borya,
      },
      {
        name: 'Cayetana van den Berg-Tejedor',
        position: 'Deputy Head Editor',
        image: cayetana,
      },
    ]
  },
  {
    year: '23/24',
    members: [
      {
        name: 'Finn Anderson',
        position: 'President',
        image: finn,
      },
      {
        name: 'Jake Bill',
        position: 'Safety Officer',
        image: jake,
      },
      {
        name: 'Cole Kiefer',
        position: 'Vice President',
        image: cole,
      },
      {
        name: 'Bruno Ceccolini',
        position: 'Secretary',
        image: bruno,
      },
      {
        name: 'Osian Guthrie',
        position: 'Head of Fund',
        image: osian24,
      },
      {
        name: 'Kabir Berger',
        position: 'Head Engineer',
        image: kabir,
      },
      {
        name: 'Jimmy Maglione',
        position: 'Editor-in-chief',
        image: jimmy,
      },
    ],
  },
];

const ContactPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <div className="min-h-screen pt-40 text-white">
        <a
          href="mailto:blockchain@st-andrews.ac.uk"
          className="text-xl no-underline hover:opacity-75"
        >
          Contact us: <span className="underline">blockchain@st-andrews.ac.uk</span>
        </a>
        <h2 className="p-6 text-4xl">Meet our committee</h2>
        {committees.map((committee) => (
          <div>
            <h2 className="p-6 text-2xl ">Class of {committee.year}</h2>
            <div className="text-whit m-auto flex w-5/6 flex-wrap items-center justify-center gap-6 p-5">
              {committee.members.map((member) => (
                <ProfileImage
                  image={member.image}
                  position={member.position}
                  name={member.name}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ContactPage;
