import React from 'react';
import RevealOnScroll from '../RevealOnScroll';

const PageSection = ({ title, text, id, img, reverse }) => {
  return (
    <RevealOnScroll>
      <div id={id} className="flex flex-col gap-8 py-24">
        <div
          className={`flex h-auto flex-col items-center justify-around p-4 md:flex-row md:p-24 ${
            reverse && `md:flex-row-reverse`
          }`}
        >
          <img className="h-[300px] w-auto" src={img} alt="" />
          <div className="mx-8 flex flex-col gap-4 pt-5 text-left text-gray-200 md:w-2/3 md:pt-0">
            <h1 className="text-5xl font-extrabold text-primary">{title}</h1>
            <p className="text-md md:text-lg">{text}</p>
          </div>
        </div>
      </div>
    </RevealOnScroll>
  );
};

export default PageSection;
