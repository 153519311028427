import { useEffect } from 'react';
import ArticlesSectionCard from './ArticlesSectionCard';
import { articles } from './data.js';

const ArticlesSection = () => {
  useEffect(() => {
    // Fetch articles from the API
    // This is just a placeholder example
  }, []);

  return (
    <div className="mx-4 bg-black text-white md:min-h-screen">
      <div className="container mx-auto max-w-6xl p-4 pt-32">
        <div className="mb-8 text-left">
          <h1 className="mb-4 text-3xl font-bold">Featured</h1>
          <p>Blockchain content written by our team.</p>
        </div>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {articles.map((article, index) => (
            <ArticlesSectionCard
              key={article.id}
              article={article}
              featured={index === 0} // Assuming the first article is the featured one
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArticlesSection;
