import { Link } from 'react-router-dom';

const ArticlesSectionCard = ({ article, featured }) => {
  return (
    <div
      className={`overflow-hidden rounded-lg shadow-lg ${
        featured ? 'col-span-full flex-row' : 'flex flex-col'
      } border-2 bg-transparent`}
    >
      {featured ? (
        // Featured Article Layout
        <Link
          to={`/article/${article.id}`}
          className="flex flex-col md:flex-row"
        >
          <div className="md:w-1/2">
            <img
              className="h-full max-h-72 w-full object-cover"
              src={article.imageSrc}
              alt="Article thumbnail"
            />
          </div>
          <div className="flex flex-1 flex-col justify-between p-6">
            {/* Text Content */}
            {TextContent(article, featured)}
          </div>
        </Link>
      ) : (
        // Standard Article Layout
        <Link to={`/article/${article.id}`} className="flex flex-col">
          <div className="flex-1">
            <img
              className="h-48 w-full object-cover"
              src={article.imageSrc}
              alt="Article thumbnail"
            />
          </div>
          <div className="flex flex-1 flex-col justify-between p-6">
            {/* Text Content */}
            {TextContent(article, featured)}
          </div>
        </Link>
      )}
    </div>
  );
};

const TextContent = (article, featured) => (
  <div
    className={`flex flex-col text-left text-white ${
      featured ? 'space-y-4' : 'space-y-2'
    }`}
  >
    <h1 className={`mb-2 font-bold ${featured ? 'text-4xl' : 'text-3xl'}`}>
      {article.title}
    </h1>
    <p className="text-lg text-gray-300">{article.author}</p>
    <p className="text-md line-clamp-3 text-white">{article.summary}</p>{' '}
  </div>
);

export default ArticlesSectionCard;
