import { Routes, Route } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';

import HomePage from './pages/HomePage';
import ArticlesPage from './pages/ArticlesPage';
import ContactPage from './pages/ContactPage';
import EventsPage from './pages/EventsPage';
import ArticlePage from './pages/ArticlePage';

function App() {
  return (
    <div className="App bg-background">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/articles" element={<ArticlesPage />}></Route>
          <Route path="/contact" element={<ContactPage />}></Route>
          <Route path="/events" element={<EventsPage />}></Route>
          <Route path="/article/:id" element={<ArticlePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
