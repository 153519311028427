import { Link } from 'react-scroll';

const Button1 = ({ text, link }) => {
  return (
    <Link to={link}>
      <div className="cursor-pointer rounded-full bg-primary px-5 py-3 text-xl text-white hover:bg-secondary">
        {text}
      </div>
    </Link>
  );
};

export default Button1;
