import { Link } from 'react-router-dom';

const NavLink = ({ text, link = false }) => {
  return (
    <li className="h-20">
      <Link
        className={`flex h-full cursor-pointer items-center text-lg transition duration-300 ease-in-out hover:scale-110 hover:transform hover:text-primary`}
        to={link}
      >
        {text}
      </Link>
    </li>
  );
};

export default NavLink;
