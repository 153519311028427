import { Link } from 'react-router-dom';

const Button1 = ({ text, link }) => {
  return (
    <Link to={link}>
      <div className="rounded-full bg-primary px-5 py-3 text-lg text-white hover:bg-secondary">
        {text}
      </div>
    </Link>
  );
};

export default Button1;
