import p5 from 'p5';
export default function sketch(p) {
  let balls = [];
  let largeCircle;
  let connections = [];
  const handle_len_rate = 2.4;
  const maxDistance = 300;
  const radius = 50;
  let color = 150;
  let mouseMoved = false; // Flag to check if the mouse has been moved

  const ballPositions = [
    [455, 129],
    [810, 73],
    [686, 363],
    [317, 459],
    [684, 726],
    [1043, 306],
    [989, 615],
    [1249, 82],
    [1492, 428],
    [1317, 733],
    [1552, 86],
    [292, 798],
  ];

  class Ball {
    constructor(x, y, r) {
      this.position = p.createVector(x, y);
      this.radius = r;
    }
    display() {
      // Calculate the distance from the ball to the cursor
      let distance = p.dist(
        p.mouseX,
        p.mouseY,
        this.position.x,
        this.position.y,
      );

      // Set a threshold for how close the cursor needs to be to display the ball
      let displayThreshold = 300; // Adjust this value as needed

      // Check if the ball is within the threshold distance
      if (distance < displayThreshold) {
        p.fill(color); // Set fill color to white
        p.noStroke(); // No outline
        p.ellipse(
          this.position.x,
          this.position.y,
          this.radius * 2,
          this.radius * 2,
        );
      }
    }
  }

  p.setup = function () {
    p.createCanvas(p.windowWidth - 20, p.windowHeight - 20);

    ballPositions.forEach((pos) =>
      balls.push(new Ball(pos[0], pos[1], radius)),
    );

    largeCircle = new Ball(676, 433, 100);
    balls.push(largeCircle);
  };

  p.draw = function () {
    if (mouseMoved) {
      // Only draw if the mouse has been moved
      p.background(0);
      balls.forEach((ball) => ball.display());
      generateConnections();
      connections.forEach((conn) => {
        drawBezier(conn);
      });
    }
    // p.background(0);
    // balls.forEach((ball) => ball.display());
    // generateConnections();
    // connections.forEach((conn) => {
    //   drawBezier(conn);
    // });
  };

  p.mouseMoved = function () {
    mouseMoved = true; // Set the flag to true when the mouse is moved
    if (largeCircle) {
      largeCircle.position = p.createVector(p.mouseX, p.mouseY);
    }
    // largeCircle.position = p.createVector(p.mouseX, p.mouseY);
  };

  function generateConnections() {
    connections = [];
    for (let i = 0; i < balls.length; i++) {
      for (let j = i - 1; j >= 0; j--) {
        let path = metaball(
          balls[i],
          balls[j],
          0.5,
          handle_len_rate,
          maxDistance,
        );
        if (path) {
          connections.push(path);
        }
      }
    }
  }

  function metaball(ball1, ball2, v, handle_len_rate, maxDistance) {
    let center1 = ball1.position;
    let center2 = ball2.position;
    let radius1 = ball1.radius;
    let radius2 = ball2.radius;
    let d = p.dist(center1.x, center1.y, center2.x, center2.y);
    let u1, u2;

    if (radius1 == 0 || radius2 == 0) return;

    if (d > maxDistance || d <= Math.abs(radius1 - radius2)) {
      return;
    } else if (d < radius1 + radius2) {
      // case circles are overlapping
      u1 = Math.acos(
        (radius1 * radius1 + d * d - radius2 * radius2) / (2 * radius1 * d),
      );
      u2 = Math.acos(
        (radius2 * radius2 + d * d - radius1 * radius1) / (2 * radius2 * d),
      );
    } else {
      u1 = 0;
      u2 = 0;
    }

    let angle1 = Math.atan2(center2.y - center1.y, center2.x - center1.x);
    let angle2 = Math.acos((radius1 - radius2) / d);
    let angle1a = angle1 + u1 + (angle2 - u1) * v;
    let angle1b = angle1 - u1 - (angle2 - u1) * v;
    let angle2a = angle1 + Math.PI - u2 - (Math.PI - u2 - angle2) * v;
    let angle2b = angle1 - Math.PI + u2 + (Math.PI - u2 - angle2) * v;
    let p1a = center1.copy().add(p5.Vector.fromAngle(angle1a).mult(radius1));
    let p1b = center1.copy().add(p5.Vector.fromAngle(angle1b).mult(radius1));
    let p2a = center2.copy().add(p5.Vector.fromAngle(angle2a).mult(radius2));
    let p2b = center2.copy().add(p5.Vector.fromAngle(angle2b).mult(radius2));

    // define handle length by the distance between
    // both ends of the curve to draw
    let totalRadius = radius1 + radius2;
    let d2 = Math.min(
      v * handle_len_rate,
      p5.Vector.sub(p1a, p2a).mag() / totalRadius,
    );

    // case circles are overlapping:
    d2 *= Math.min(1, (d * 2) / (radius1 + radius2));

    radius1 *= d2;
    radius2 *= d2;

    return {
      p1a,
      p2a,
      p2b,
      p1b,
      handle1: p5.Vector.fromAngle(angle1a - Math.PI / 2).mult(radius1),
      handle2: p5.Vector.fromAngle(angle2a + Math.PI / 2).mult(radius2),
      handle3: p5.Vector.fromAngle(angle2b - Math.PI / 2).mult(radius2),
      handle4: p5.Vector.fromAngle(angle1b + Math.PI / 2).mult(radius1),
    };
  }

  function drawBezier({
    p1a,
    p2a,
    p2b,
    p1b,
    handle1,
    handle2,
    handle3,
    handle4,
  }) {
    // p.stroke(255);
    // p.noFill();
    p.fill(color);
    p.noStroke();
    p.beginShape();
    p.vertex(p1a.x, p1a.y);
    p.bezierVertex(
      p1a.x + handle1.x,
      p1a.y + handle1.y,
      p2a.x + handle2.x,
      p2a.y + handle2.y,
      p2a.x,
      p2a.y,
    );
    p.bezierVertex(
      p2a.x - handle2.x,
      p2a.y - handle2.y,
      p2b.x - handle3.x,
      p2b.y - handle3.y,
      p2b.x,
      p2b.y,
    );
    p.bezierVertex(
      p2b.x + handle3.x,
      p2b.y + handle3.y,
      p1b.x + handle4.x,
      p1b.y + handle4.y,
      p1b.x,
      p1b.y,
    );
    p.bezierVertex(
      p1b.x - handle4.x,
      p1b.y - handle4.y,
      p1a.x - handle1.x,
      p1a.y - handle1.y,
      p1a.x,
      p1a.y,
    );
    p.endShape(p.CLOSE);
  }

  p.windowResized = function () {
    p.resizeCanvas(p.windowWidth - 20, p.windowHeight - 20);
    // Optionally, reposition or resize elements based on the new canvas size
  };
}
