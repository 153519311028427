const ProfileImage = ({ image, name, position }) => {
  return (
    <div className="flex flex-col items-center justify-center text-xs md:text-lg">
      <div
        className="mb-2 h-[150px] w-[120px] rounded-md bg-white bg-cover bg-center md:h-[300px] md:w-[250px]"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <p className="font-bold">{position}</p>
      <p>{name}</p>
    </div>
  );
};

export default ProfileImage;
