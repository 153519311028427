import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Button1 from '../Buttons/Button1';
import Button2 from '../Buttons/Button2';

const SideBar = ({ isOpen, toggle }) => {
  return (
    <aside
      className={`fixed z-50 grid h-full w-full place-items-center bg-background transition duration-300 ease-in-out ${
        isOpen
          ? 'top-0 translate-y-0 opacity-100'
          : 'top-0 -translate-y-full opacity-0'
      }`}
    >
      <div className="absolute right-8 top-10 cursor-pointer text-3xl ">
        <FaTimes className="text-white" onClick={toggle} />
      </div>
      <div className="flex flex-col items-center justify-center text-white">
        <ul className="grid grid-rows-2 gap-10 text-center text-xl">
          <li>
            <Link to="https://stabsmagazine.my.canva.site/" className="py-4 hover:text-primary">
              Magazine
            </Link>
          </li>
          <li>
            <Link to="/articles" className="py-4 hover:text-primary">
              Articles
            </Link>
          </li>
          <li>
            <Link to="/events" className="py-4 hover:text-primary">
              Events
            </Link>
          </li>
        </ul>
        <div className="mt-12 flex justify-center">
          <div className="flex flex-col gap-4">
            <Button2 text={'Contact'} link={'/contact'} />
            <Button1
              text="Join"
              link={
                'https://www.yourunion.net/activities/societies/explore/9310/"'
              }
            />
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
