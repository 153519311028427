import { React, useState } from 'react';
import SideBar from '../components/SideBar/SideBar';
import NavBar from '../components/NavBar/NavBar';
import ArticlesSection from '../components/Articles/ArticlesSection';

function ArticlesPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <ArticlesSection />
    </>
  );
}

export default ArticlesPage;
