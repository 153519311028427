import { React, useEffect, useState } from 'react';
import EventItem from '../components/EventItem/EventItem';
import SideBar from '../components/SideBar/SideBar';
import NavBar from '../components/NavBar/NavBar';

function EducationPage() {
  const [upcoming, setUpcoming] = useState([]);
  const [past, setPast] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setUpcoming([
      {
        name: 'Magazine Launch Party',
        date: null,
        location: 'To be confirmed',
        description:
          'Join us for the launch of our first ever magazine, featuring articles from our members',
      },
    ]);
    setPast([
      {
        name: 'CV Workshop',
        date: new Date(2023, 11, 6, 18, 0, 0),
        location: 'Brewco, 2nd Floor',
        description:
          '1 to 1 CV workshop with one of our experienced committee members',
      },
    ]);
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      <div className="flex min-h-screen flex-col items-center p-8 pt-40 text-white">
        {upcoming && upcoming.length > 0 && (
          <h1 className="text-5xl font-bold">Upcoming Events</h1>
        )}
        <div className="flex flex-col items-center gap-4 p-8">
          {upcoming.map((event) => (
            <EventItem event={event} />
          ))}
        </div>
        {past && past.length > 0 && (
          <h1 className="text-5xl font-bold">Past Events</h1>
        )}
        <div className="flex flex-col items-center gap-4 p-8">
          {past.map((event) => (
            <EventItem event={event} />
          ))}
        </div>
      </div>
    </>
  );
}

export default EducationPage;
