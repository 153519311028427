import React from 'react';
import { Link } from 'react-router-dom';

const Button1 = ({ text, link, color }) => {
  return (
    <Link to={link}>
      <div className="rounded-full border-2 bg-transparent px-5 py-3 text-lg text-white hover:opacity-75">
        {text}
      </div>
    </Link>
  );
};

export default Button1;
